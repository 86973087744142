import { REAPPLY_APPLICATIONS_TAB } from './reapplyApplications-const';

export default class reapplyApplicationsService {
  constructor(api) {
    this.api = api;
  }

  async list(query) {
    try {
      if (!('tab' in query)) query.tab = REAPPLY_APPLICATIONS_TAB.TASKS;
      let res = await this.api.list(query);
      if (!res.results) throw new Error('Something wrong and candidates not loading. Please, reload page or try later.');
      let data = {
        results: res.results,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous
        },
        counts: res.counts
      };
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // send_result_emails

  async addLog(id, data) {
    try {
      let _data = {...data};
      if (!_data.task_due_date) delete _data.task_due_date;
      let res = await this.api.addLog(id, _data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async saveNote(examID, data) {
    try {
      let res = await this.api.patch(examID, {notes: data});
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateDueDate(examID, data) {
    try {
      let res = await this.api.patch(examID, {task_due_date: data});
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
