export const REAPPLY_APPLICATIONS_HEADERS = [
  {text: '#', value: 'index', class: 'ra-index', sortable: false},
  {text: 'Reserved info', value: 'datetime', class: 'ra-reserved-info', sortable: true},
  {text: 'Name', value: 'name', class: 'ra-name', sortable: false},
  {text: 'App ID', value: 'appID', class: 'ra-app-id', sortable: false},
  {text: 'CDN', value: 'cdn', class: 'ra-cdn', sortable: false},
  {text: 'DOB/Phone', value: 'dob', class: 'ra-dob', sortable: false},
  {text: 'Is reapplied', value: 'task_is_reapplied', class: 'ra-is-reapplied', sortable: false},
  {text: 'Result', value: 'results', class: 'ra-results', sortable: false},
  {text: 'Status', value: 'status', class: 'ra-status', sortable: false},
  {text: 'Send results', value: 'send_results', class: 'ra-send-results', sortable: false},
  {text: 'Course Type', value: 'course_type', class: 'ra-course-type', sortable: false},
  {text: 'Product type', value: 'product_type', class: 'ra-product-type', sortable: false},
  {text: 'Email', value: 'email', class: 'ra-email', sortable: false},
  {text: 'Due date', value: 'task_due_date', class: 'ra-due-date', sortable: true},
  {text: 'State', value: 'state', class: 'ra-state', sortable: false},
  {text: 'Note / State', value: 'notes_state', class: 'ra-notes', sortable: false}
];

export const JUMBOTRON_FILTERS = {
  EXAM_RESULTS: 'results'
};

export const EXAM_RESULTS_LIST = {
  FAILED: {label: 'Failed', value: 'FAILED'},
  NOT_COME: {label: 'Didn’t come', value: 'NOT_CAME'},
  NO_RESULT: {label: 'No result', value: 'NO_RESULTS'}
};

export const REAPPLY_APPLICATIONS_TYPES_LIST = {
  NO_PICK_UP: {label: 'Didn\'t pick up', value: 'NO_PICK_UP'},
  CALL_BACK: {label: 'Call back', value: 'CALL_BACK'},
  WILL_REAPPLY: {label: 'Will reapply/pay', value: 'WILL_REAPPLY'},
  UNPAID_REAPPLIED: {label: 'Not Paid Reapplied', value: 'UNPAID_REAPPLIED'},
  PAID_REAPPLIED: {label: 'Paid Reapplied', value: 'PAID_REAPPLIED'},
  CANCELED: {label: 'Canceled - after contact', value: 'CANCELED'},
  CANCELED_NO_CON: {label: 'Canceled - no contact', value: 'CANCELED_NO_CON'},
  OTHER: {label: 'Other', value: 'OTHER'}
};

export const REAPPLY_APPLICATIONS_TAB = {
  TASKS: 'tasks',
  FUTURE: 'future',
  UNPAID_REAPPLIED: 'unpaid_reapplied',
  PAID_REAPPLIED: 'paid_reapplied',
  CANCELED: 'canceled'
};
