<template lang="pug">
  .page-container.reapply-applications
    portal(to="toolbar-title")
      toolbar-title Reapply task list

    .page-container__block
      search-widget(@change="loadList")

    .page-container__block
      jumbotron
        template(v-slot:content)
          app-select(
            :options="examResultsList"
            :value="$route.query[jumbotronFilters.EXAM_RESULTS]"
            filter
            @input="setFilter(jumbotronFilters.EXAM_RESULTS, $event)"
            placeholder="Exam Results"
            slug-key='value'
            slug-value='label'
            show-close-btn
          )

    .page-container__block.border-b
      .d-flex
        tabs(@change="changeTabs" :counts="counts")
      .d-flex
        pagination(:pagination="pagination" :loading="loading" @change="loadList")

    .page-container__list
      list(
        :headers="headers"
        :list="list"
        :loading="loading"
        :pagination="pagination"
        :primaryPagination="primaryPagination"
        @loadMore="loadMore"
        @change:sorting="updateSorting"
        @needToUpdate="loadList"
      )
</template>

<script>
import api from '@/services/api/api-reapplyApplications'
import reapplyAppService from './core/reapplyApplications-service'
import ResultsBroadcast from '@/app/admin/modules/results/core/models/resultsExamBroadcast'

import appSelect from '@/components/global/actions/BaseSelect.vue';

import PaginationModel from '@/models/paginationModel'
import paginationMixin from '@/mixins/pagination.mixin'
import errorsMixin from '@/mixins/errors.mixin'

import {
  JUMBOTRON_FILTERS,
  EXAM_RESULTS_LIST,
  REAPPLY_APPLICATIONS_HEADERS,
  REAPPLY_APPLICATIONS_TAB
} from './core/reapplyApplications-const'
import { RESERVED_EXAM } from '@/app/admin/modules/results/core/models/resultModel';
import ResultReservedExam, { RESULT, RESULT_STATUS } from '@/app/admin/modules/results/core/models/resultReservedExam';

export default {
  mixins: [paginationMixin, errorsMixin],

  data: () => ({
    reapplyService: new reapplyAppService(api),
    pagination: {},
    primaryPagination: {},
    list: [],
    loading: false,
    headers: REAPPLY_APPLICATIONS_HEADERS,
    counts: {
      [REAPPLY_APPLICATIONS_TAB.TASKS]: '...',
      [REAPPLY_APPLICATIONS_TAB.FUTURE]: '...',
      [REAPPLY_APPLICATIONS_TAB.REAPPLIED]: '...',
      [REAPPLY_APPLICATIONS_TAB.CANCELED]: '...'
    },
    jumbotronFilters: JUMBOTRON_FILTERS,
    examResultsList: Object.values(EXAM_RESULTS_LIST),
    examBroadcast: null
  }),

  created() {
    this.initWS()
  },

  async mounted() {
    await this.setDefaultPagination()
    await this.preSelectPageData()
    await this.loadList()
  },

  methods: {
    async loadList(more = false, query) {
      try {
        this.loading = true
        let {results, pagination, counts} = await this.reapplyService.list({...this.$route.query, ...query})
        if (more) this.list.push(...results)
        else this.list = results
        await this.$nextTick()
        this.counts = counts
        this.pagination = new PaginationModel(this.setPaginationParams(pagination))
        if (!more) this.setPrimaryPagination()
      } catch (error) {
        console.log(error)
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async loadMore() {
      if (!this.pagination) return
      await this.onLoadMoreClick()
      await this.loadList(true)
    },

    setPrimaryPagination() {
      this.primaryPagination = { page: this.pagination.page, size: this.pagination.size }
    },

    async initWS() {
      let tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.examBroadcast = new ResultsBroadcast(tokens.access)
      this.examBroadcast.startListen(this.examResultHandler)
    },

    examResultHandler(msg) {
      let itemIndex = this.list.findIndex((item) => item.id === msg[RESERVED_EXAM.ID])
      if (itemIndex < 0) return
      for (let field in msg) {
        if (field in this.list[itemIndex]) {
          if (field === 'result')
            this.list[itemIndex].result = new ResultReservedExam(msg[RESERVED_EXAM.RESULT])
          else this.list[itemIndex][field] = msg[field]

        }
      }
      if ('result' in msg && (msg[RESERVED_EXAM.RESULT][RESULT.STATUS] === RESULT_STATUS.LOADED || msg[RESERVED_EXAM.RESULT][RESULT.STATUS] === RESULT_STATUS.FAILED)) this.list[itemIndex].loading = false
    },

    async updateSorting(ordering) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ordering: ordering}})
      await this.loadList()
    },

    updateItem(ID, field, data) {
      let itemIndex = this.list.findIndex(item => item.id === ID)
      if (itemIndex < 0) return
      this.list[itemIndex][field] = data
    },

    async changeTabs() {
      this.clear()
      await this.loadList()
    },

    clear() {
      this.list = []
    },

    async setFilter(type, payload, load = true) {
      if (this.loading) return
      if (this.$route.query[type] == payload) return
      if (!payload || !payload.length) return await this.setQuery({[type]: undefined}, load)

      try {
        this.loading = true
        await this.setQuery({[type]: payload}, load);
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    resetFilter(type) {
      if (this.loading) return
      if (!this.$route.query[type]) return
      this.setFilter([type], undefined)
    },

    async setQuery(data, load) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ...data}})
      if (load) await this.loadList()
    },

    async preSelectPageData() {
      if (!this.$route.query.ordering) await this.setQuery({ordering: '-datetime'}, false)
      if (!this.$route.query.tab) await this.setFilter('tab', REAPPLY_APPLICATIONS_TAB.TASKS, false)
      if (!this.$route.query[this.jumbotronFilters.EXAM_RESULTS]) await this.setFilter([this.jumbotronFilters.EXAM_RESULTS], 'FAILED', false)
    }
  },

  provide: function () {
    return {
      reapplyService: this.reapplyService,
      getDefaultPagination: this.getDefaultPagination,
      getDefaultPageSize: this.getDefaultPageSize,
      updateItem: this.updateItem,
      setQuery: this.setQuery
    }
  },

  beforeRouteLeave(to, from, next) {
    this.examBroadcast.close()
    next()
  },

  components: {
    appSelect,
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    jumbotron: () => import('@/components/global/fields/Jumbotron.vue'),
    searchWidget: () => import('@/components/widget/SearchWidget.vue'),
    pagination: () => import('@/components/widget/PaginationWidget.vue'),
    tabs: () => import('./components/ReapplyApplicationsTabs.vue'),
    list: () => import('./components/ReapplyApplicationsList.vue')
  }
}
</script>